import { AspectRatio, Modal } from "@xcorejs/ui";
import { VideoControls } from "components/Video";
import { FC, useMemo } from "react";
import { parse } from "url";

interface Props {
  url: string;
}

const VideoModal: FC<Props & VideoControls> = ({ url, loop }) => {
  return (
    <Modal
      background="rgba(0,0,0,0)"
      padding={0}
      overflow="visible"
      _close={{
        ...{ size: "lg" },
        position: "absolute",
        top: "-3rem",
        right: "-3rem",
        _icon: {
          fill: "white",
          fillHover: "#d30031"
        }
      }}
      _overlay={{
        background: "rgba(15, 31, 40, 0.75)",
        zIndex: 1000
      }}
      size="lg"
      width="80%"
    >
      <VideoPlayer url={url} loop={loop} />
    </Modal>
  );
};

export default VideoModal;

export type VideoProps = {
  url: string;
  loop?: boolean;
  noControl?: boolean;
  mute?: boolean;
  autoplay?: boolean;
};

export const VideoPlayer: FC<VideoProps> = ({ url, loop, noControl, mute, autoplay }) => {
  const { path, host, query } = useMemo(() => parse(url, true), [url]);

  const src = {
    "vimeo.com": `https://player.vimeo.com/video${path ?? ""}?transparent=0&color=e00034&&${autoplay ? "autoplay=1" : ""}${loop ? "&loop=1" : ""}${mute ? "&muted=1" : ""}${noControl ? "&controls=0" : ""}`,
    "player.vimeo.com": `${url}`,
    "youtube.com": `https://www.youtube.com/embed/${query.v as string}?autoplay=1&${loop ? "loop=1" : ""}`,
    "youtu.be": `https://www.youtube.com/embed/${path ?? ""}?transparent=0&autoplay=1&color=e00034${loop ? "&loop=1" : ""}`
  }[(host ?? "").replace("www.", "")];

  // If you want to show thumbnail before the iframe is loaded be aware that there were others before you who tried
  // Already tried these:
  //   onLoad, onLoadedData
  //   https://stackoverflow.com/questions/20572734/load-event-not-firing-when-iframe-is-loaded-in-chrome
  //   https://github.com/vercel/next.js/issues/39451
  //   `@u-wave/react-vimeo` This package is most promising but we'd need api key that is only available with vimeo pro account which robe doesn't have

  return (
    <AspectRatio ratio={9 / 16} background="black">
      <iframe
        id={path ?? `ID:${Math.random()}`} // iframe has to have unique id, fallback for Math.random() should provide sufficient pseudo random number
        src={src}
        frameBorder={0}
        allow="autoplay;fullscreen"
        allowFullScreen
        width="100%"
        height="100%"
      />
    </AspectRatio>
  );
};
