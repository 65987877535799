import { Link } from "@appiodev/xcore-client/xcore-ui";
import { Box, Flex, Grid, Link as UILink, Text } from "@xcorejs/ui";
import ExternalSVG from "components/ExternalSVG";
import ApplicationPickerIcon from "components/icons/16/application-picker.svg";
import ArchitectureIcon from "components/icons/32/architecture.svg";
import ChurchIcon from "components/icons/32/church.svg";
import ClubIcon from "components/icons/32/club.svg";
import EventsIcon from "components/icons/32/events.svg";
import OutdoorsIcon from "components/icons/32/outdoors.svg";
import ShipIcon from "components/icons/32/ship.svg";
import TheatreIcon from "components/icons/32/theatre.svg";
import TouringIcon from "components/icons/32/touring.svg";
import TvIcon from "components/icons/32/tv.svg";
import useDropdown from "design-system/xcore/Dropdown";
import IconBox from "design-system/xcore/IconBox";
import { FC, ReactNode } from "react";
import { useLayout } from "xcore";

interface IApplicationPickerLink {
  icon: ReactNode;
  bgImg: string;
}

const links: Record<string, IApplicationPickerLink> = {
  "Touring": {
    icon: <TouringIcon />,
    bgImg: "touring.jpg"
  },
  "TV": {
    icon: <TvIcon />,
    bgImg: "television.jpg"
  },
  "Theatre": {
    icon: <TheatreIcon />,
    bgImg: "theatre.jpg"
  },
  "Architecture": {
    icon: <ArchitectureIcon />,
    bgImg: "architecture.jpg"
  },
  "Events": {
    icon: <EventsIcon />,
    bgImg: "events.jpg"
  },
  "Club": {
    icon: <ClubIcon />,
    bgImg: "club.jpg"
  },
  "Church": {
    icon: <ChurchIcon />,
    bgImg: "church.jpg"
  },
  "Cruise ship": {
    icon: <ShipIcon />,
    bgImg: "cruiseship.jpg"
  },
  "Outdoors": {
    icon: <OutdoorsIcon />,
    bgImg: "outdoors.jpg"
  }
};

const ApplicationPicker: FC = () => {
  const { subheader, stringify, general, file } = useLayout();

  const { OnClick, OnHover } = useDropdown(
    {
      position: "absolute",
      locationY: "top",
      locationX: "right",
      _firstDropdownProps: {
        left: 0,
        marginLeft: "-2rem",
        top: "100%",
        maxWidth: "none"
      }
    }
  );

  return (
    <OnHover
      props={{
        children: (
          <Box
            _after={{
              content: '""',
              display: "flex",
              width: "200vw",
              left: "-50%",
              top: "0",
              height: "200vh",
              position: "fixed",
              background: "rgba(30, 30, 30, 0.5)",
              pointerEvents: "none"
            }}
          >
            <Grid
              columns="repeat(3, 33rem)"
              rows="repeat(3, minmax(min-content, 20rem))"
              height="calc(100vh - 14rem)"
              maxHeight="60rem"
              position="relative"
              zIndex={1004}
            >
              {subheader.appPicker.values.applications?.map((app, i) => (
                <Link
                  key={i}
                  content={app.relation}
                >
                  <Flex
                    background={`#1e1e1e url('${file(app.image, "330x200@2x") as string}') center center`}
                    backgroundSize="cover"
                    role="group"
                    alignItems="center"
                    flexDirection="column"
                    justifyContent="center"
                    filter="grayscale(100%) brightness(2.5)"
                    transform="translateZ(0) scale(1.0, 1.0)"
                    transition="filter 300ms"
                    width="100%"
                    height="100%"
                    _hover={{ filter: "grayscale(0%) brightness(1) blur(0.2px)" }}
                    overflow="hidden"
                    backface-visibility="hidden"
                  >
                    {file(app.icon) && (
                      <ExternalSVG
                        src={file(app.icon)!}
                        opacity={0}
                        fill="#fff"
                        transition="opacity 300ms, margin 300ms"
                        width="3.2rem"
                        height="3.2rem"
                        _groupHover={{
                          opacity: 1,
                          mb: "2rem"
                        }}
                      />
                    )}
                    <Text
                      textTransform="uppercase"
                      fontFamily="avenir"
                      fontSize="1.5rem"
                      fontWeight={600}
                      lineHeight="2rem"
                      color="white"
                    >
                      {stringify(app.relation.values.thumbnailTitle)
                        ? stringify(app.relation.values.thumbnailTitle)
                        : stringify(app.relation.title)}
                    </Text>
                  </Flex>
                </Link>
              ))}
            </Grid>
          </Box>)
      }}
    >
      {active => (
        <UILink
          type="simple"
          as="a"
          display="flex"
          alignItems="center"
          height="5rem"
          px="2rem"
          _hover={{ bg: "#fff", color: "robe" }}
          transform="translateX(-2rem)"
          fontSize={{ _: "1.3rem", lg: "1.4rem" }}
          fontWeight={600}
          lineHeight="2.1rem"
          color={active ? "primary" : "lightGray"}
          transition="color 300ms, background 300ms"
          background={active ? "#fff" : undefined}
          zIndex={active ? 11000 : 10}
          position="relative"
          whiteSpace="nowrap"
        >
          <IconBox width="3.2rem" minWidth="3.2rem" height="3.2rem" icon={<ApplicationPickerIcon />} mr="1rem" />
          {stringify(general.values.appPicker)}
        </UILink>
      )}
    </OnHover>
  );
};

export default ApplicationPicker;
