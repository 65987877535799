import { AspectRatio, Img } from "@xcorejs/ui";
import RobeDoubleCarousel from "design-system/robe/Carousel/Double";
import { RobeLightboxProps } from "design-system/robe/RobeLightbox";
import { FC } from "react";
import { useGallery } from "templates/products/ProductDetail/data";

interface Props extends Omit<RobeLightboxProps, "index" | "white" | "images"> {
  images: [string, string, string][];
}

const ImagesCarousel: FC<Props> = ({ images, ...rest }) => {
  const [openLightbox] = useGallery({ images: images.map(([,, retina]) => retina), ...rest });

  return images.length === 1 ? (
    <AspectRatio ratio={9 / 16}>
      <Img
        src={images[0][0]}
        // todo uncomment once requesting 2x sizes on BE is fixed
        // srcSet={`${images[0][0]} 1x, ${images[0][2]} 2x`}
        alt={images[0][1]}
        height="100%"
        width="auto"
        cursor="pointer"
        onClick={() => openLightbox(0)}
        loading="lazy"
      />
    </AspectRatio>
  ) : (
    <RobeDoubleCarousel>
      {images.map(([src, alt, retina], i) => (
        <AspectRatio key={i} ratio={9 / 16}>
          <Img
            src={src}
            // todo uncomment once requesting 2x sizes on BE is fixed
            // srcSet={`${src} 1x, ${retina} 2x`}
            alt={alt}
            height="100%"
            width="auto"
            cursor="pointer"
            onClick={() => openLightbox(i)}
          />
        </AspectRatio>
      ))}
    </RobeDoubleCarousel>
  );
};

export default ImagesCarousel;
