/* eslint-disable react-hooks/rules-of-hooks */
import { Box, BoxProps, useTheme } from "@xcorejs/ui";
import { FC, MouseEvent, PropsWithChildren, ReactNode, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { XcoreTheme } from "../theme";

export interface DropdownState {
  x: number;
  y: number;
}

interface DropdownProps<P extends {}> {
  props: PropsWithChildren<P>;
  children: ReactNode | ((active: boolean) => ReactNode);
}

interface DropdownOptions {
  position: "relative" | "absolute" | "fixed";
  _dropdownProps?: BoxProps;
  _firstDropdownProps?: BoxProps;
  locationX?: "left" | "right" | "center";
  locationY?: "top" | "center" | "bottom";
}

type DropdownStyleProps = {
  locationX?: "left" | "right" | "center";
  locationY?: "top" | "center" | "bottom";
} & BoxProps;

const useDropdown = <P extends {}>(options: DropdownOptions, Dropdown?: FC<P & BoxProps>) => {
  const { position, locationX, locationY, _dropdownProps, _firstDropdownProps } = options;

  const component = (trigger: "click" | "hover" | "context") => {
    const c: FC<DropdownProps<P> & BoxProps> = ({
      children,
      props,
      ...rest
    }) => {
      const [state, set] = useState<DropdownState | null>(null);
      const ref = useRef<HTMLDivElement | null>(null);

      const open = (x: number, y: number) => set({ x, y });
      const close = () => set(null);

      const closeHandler = () => (e: Event) => {
        if (ref.current && !ref.current.contains(e.target as Node)) {
          state && close();
        }
      };

      useEffect(() => {
        const handler1 = closeHandler();
        const handler2 = () => trigger === "context" && closeHandler();
        document.addEventListener("click", handler1);
        document.addEventListener("contextmenu", handler2);

        return () => {
          document.removeEventListener("click", handler1);
          document.removeEventListener("contextmenu", handler2);
        };
      });

      const handlers = {
        click: () => ({ onClick: () => open(0, 0) }),
        hover: () => ({
          onMouseEnter: () => open(0, 0),
          onMouseLeave: () => close()
        }),
        context: () => ({
          onContextMenu: (e: MouseEvent) => {
            e.preventDefault();
            open(e.pageX, e.pageY);
          }
        })
      }[trigger]();

      const theme = useTheme() as XcoreTheme;
      const dropdownProps = theme.dropdown.box;

      return (
        <Box ref={ref} position="relative" {...rest} {...handlers}>
          {typeof children === "function"
            ? (children as any)(state !== null)
            : children}
          {state && (
            <DropdownStyle
              position={position}
              locationX={locationX}
              locationY={locationY}
              {...dropdownProps}
              {..._dropdownProps}
              _firstOfType={_firstDropdownProps}
            >
              {Dropdown ? <Dropdown {...props} /> : <>{props.children}</>}
            </DropdownStyle>
          )}
        </Box>
      );
    };

    c.displayName = "On" + trigger[0].toUpperCase() + trigger.slice(1) + "Dropdown";

    return c;
  };

  return {
    OnClick: component("click"),
    OnHover: component("hover"),
    OnContext: component("context")
  };
};

export default useDropdown;

const DropdownStyle = styled(Box)<DropdownStyleProps>`
  ${props => props.locationX === "right" && css`
    left: 100%;
  `}

  ${props => props.locationX === "left" && css`
    right: 100%;
  `}

  ${props => props.locationY === "top" && css`
    top: 0;
  `}

  ${props => props.locationY === "bottom" && css`
    bottom: 0;
  `}
`;

DropdownStyle.defaultProps = {
  position: "fixed",
  zIndex: 100000
};
